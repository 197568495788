@font-face {
  font-family: 'MuseoSans-700';
  src: url('https://www.personalized-medicine.at/typo3conf/ext/f_base/Resources/Public/fonts/36159C_0_0.eot');
  src: url('https://www.personalized-medicine.at/typo3conf/ext/f_base/Resources/Public/fonts/36159C_0_0.eot?#iefix') format('embedded-opentype'), url('https://www.personalized-medicine.at/typo3conf/ext/f_base/Resources/Public/fonts/36159C_0_0.woff2') format('woff2'), url('https://www.personalized-medicine.at/typo3conf/ext/f_base/Resources/Public/fonts/36159C_0_0.woff') format('woff'), url('https://www.personalized-medicine.at/typo3conf/ext/f_base/Resources/Public/fonts/36159C_0_0.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'MuseoSans-300';
  src: url('https://www.personalized-medicine.at/typo3conf/ext/f_base/Resources/Public/fonts/36159C_1_0.eot');
  src: url('https://www.personalized-medicine.at/typo3conf/ext/f_base/Resources/Public/fonts/36159C_1_0.eot?#iefix') format('embedded-opentype'), url('https://www.personalized-medicine.at/typo3conf/ext/f_base/Resources/Public/fonts/36159C_1_0.woff2') format('woff2'), url('https://www.personalized-medicine.at/typo3conf/ext/f_base/Resources/Public/fonts/36159C_1_0.woff') format('woff'), url('https://www.personalized-medicine.at/typo3conf/ext/f_base/Resources/Public/fonts/36159C_1_0.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

body {
  min-height: 100vh;
  margin: 0;
  color: #606e77;
  font-family: 'MuseoSans-300', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.tooltip {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: rgba(0, 216, 239, .95);
  padding: 4px;
  border-radius: 4px;
}
.tooltip:hover {
  padding: 4px 12px;
  background-color: rgba(0, 216, 239, .95);
  color: #fff;
  text-decoration: none;
}
.tooltip:hover img {
  display: none;
}
.tooltip:hover img.icon {
  display: block;
}
.ttp {
  max-width: 280px;
  background-color: rgba(68, 85, 95, 1)!important;
}
.selectChapter {
  color: rgb(68, 85, 95);
  text-decoration: none;
  display: flex;
  cursor: pointer;
  transition: all 1s;
  padding: 30px 16px;
}
.selectChapterDisabled {
  color: rgb(120, 120, 120);
  text-decoration: none;
  display: flex;
  cursor: pointer;
  transition: all 1s;
  padding: 30px 16px;
}
a {
  color: #999;
  text-decoration: none;
}
a:hover {
  color: #252525;
}
.selectChapter:hover {
  background-color: rgba(179, 179, 179, 1);
}
.selectChapterDisabled:hover {
  color: rgb(68, 85, 95);
  background-color: rgba(179, 179, 179, 1);
}
